import { Tooltip, ActionIcon, Box } from '@mantine/core'
import dayjs from 'dayjs'
import { DataTable } from 'mantine-datatable'
import { VerificationOfEmployment } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'

import StyledBadge from 'src/components/DataDisplay/StyledBadge/StyledBadge'
import { VoEStatus } from 'src/graphql/types/verificationOfEmployments'
import IconCalendar from 'src/icons/IconCalendar'
import IconEmptyInbox from 'src/icons/IconEmptyInbox'
import IconInfoCircle from 'src/icons/IconInfoCircle'
import IconNavigate from 'src/icons/IconNavigate'
import { calculateAverageJobDuration } from 'src/lib/employment.utils'
import { formatSnakeValue } from 'src/lib/formatters'

const colorByStatus: Record<VoEStatus, 'gray' | 'blue' | 'green' | 'orange'> = {
  NOT_STARTED: 'gray',
  REQUESTED: 'blue',
  PENDING_REVIEW: 'orange',
  COMPLETED: 'green',
}

export interface VoERequestsTableProps {
  loading: boolean
  voeList: VerificationOfEmployment[]
}

const DUE_DATE_LIMIT_DAYS = 30
const DUE_DATE_ALERT_DAYS = 10

const isPastDue = (date: Date, dueDateLimit, dueDateAlert) => {
  const dueDate = new Date(date.getTime() + dueDateLimit * 1000 * 60 * 60 * 24)
  const daysUntilDueDate = Math.ceil(
    (dueDate.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)
  )
  const isPastDue = daysUntilDueDate < 0
  const alert = daysUntilDueDate <= dueDateAlert
  return { isPastDue, alert, daysUntilDueDate }
}

const getDaysTillDueDate = (createdAtDate) => {
  const dueDate = new Date(
    createdAtDate.getTime() + DUE_DATE_LIMIT_DAYS * 1000 * 60 * 60 * 24
  )
  return Math.ceil(
    (dueDate.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)
  )
}

const VoERequestsTable = ({ loading, voeList }: VoERequestsTableProps) => {
  const onAction = (voe: VerificationOfEmployment) => {
    navigate(routes.voeRequestDetails({ id: voe.voeId }))
  }

  return (
    <div className="flex-1">
      <DataTable
        h={11}
        minHeight={300}
        highlightOnHover
        fetching={loading}
        withRowBorders={true}
        emptyState={
          voeList && voeList.length === 0 ? (
            <div className="flex flex-1 flex-col items-center justify-center  ">
              <IconEmptyInbox className="h-40 fill-none" />
              <div className="text-md font-medium text-doubleNickel-gray-800">
                No VoE Records Found
              </div>
            </div>
          ) : (
            <></>
          )
        }
        classNames={{
          header:
            'h-11 bg-doubleNickel-gray-50 text-xs font-medium text-doubleNickel-gray-600',
        }}
        idAccessor="voeId"
        onRowClick={({ record, index, event }) => {
          onAction(record)
        }}
        columns={[
          {
            accessor: 'driverName',
            title: 'Driver Name',
            cellsClassName: 'w-[20%]',
            render: (voe) => (
              <div className="flex flex-row items-center gap-3">
                <div>
                  {voe.applicant ? (
                    <Tooltip label="Go to profile">
                      <Box
                        variant="link"
                        // righticon={
                        //   <IconNavigate className="fill-none stroke-doubleNickel-brand-500"></IconNavigate>
                        // }
                        className="font-medium text-doubleNickel-brand-500 hover:underline"
                        onClick={(event) => {
                          event.stopPropagation()
                          navigate(
                            routes.applicantDetails({
                              id: voe.applicant.applicantId,
                              tab: 'application',
                            })
                          )
                        }}
                      >
                        {`${voe.applicant.firstName} ${voe.applicant.lastName}`}
                      </Box>
                    </Tooltip>
                  ) : (
                    <div className="flex items-center gap-1">
                      <p>{voe.driverName || 'juan pirulo'}</p>
                      <Tooltip className="text-xs" label="Driver not found">
                        <div>
                          <IconInfoCircle className="fill-none stroke-doubleNickel-brand-500"></IconInfoCircle>
                        </div>
                      </Tooltip>
                    </div>
                  )}
                </div>
              </div>
            ),
          },
          {
            accessor: 'type',
            title: 'Company Name',
            titleClassName: 'px-4',
            cellsClassName: 'px-4 w-[20%]',
            render: (voe) => (
              <div className="flex flex-row items-center gap-3">
                <div>{voe.companyName}</div>
              </div>
            ),
          },
          {
            accessor: 'createdAt',
            title: 'Request Date',
            cellsClassName: 'w-[10%]',
            render: (voe) => {
              const daysTillDueDate = getDaysTillDueDate(
                new Date(voe.createdAt)
              )
              const showDueDateIcon = daysTillDueDate <= DUE_DATE_ALERT_DAYS
              const isPastDue = daysTillDueDate < 0

              const message = isPastDue
                ? `${Math.abs(daysTillDueDate)} day${
                    Math.abs(daysTillDueDate) > 1 ? 's' : ''
                  } past due`
                : `Due in ${daysTillDueDate} day${
                    daysTillDueDate > 1 ? 's' : ''
                  }`

              return (
                <div>
                  <div className="flex flex-row items-center justify-between">
                    {voe.createdAt
                      ? dayjs(voe.createdAt).format('MM/DD/YYYY')
                      : '-'}
                    {showDueDateIcon && voe.status != VoEStatus.COMPLETED && (
                      <Tooltip label={message}>
                        <div>
                          <IconCalendar
                            className={`h-4 fill-none ${
                              isPastDue
                                ? 'stroke-doubleNickel-error-600'
                                : 'stroke-doubleNickel-warning-500'
                            }`}
                          />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </div>
              )
            },
          },
          {
            accessor: 'status',
            title: 'Status',
            cellsClassName: 'w-[10%]',
            render: (voe) => (
              <StyledBadge
                textTransform="capitalize"
                color={colorByStatus[voe.status]}
              >
                {formatSnakeValue(voe.status)}
              </StyledBadge>
            ),
          },
          {
            accessor: 'startDate',
            title: (
              <Tooltip label="Employment Start date">
                <p>Start Date</p>
              </Tooltip>
            ),
            cellsClassName: 'w-[10%]',
            render: (voe) =>
              voe.requestContent.startDate
                ? dayjs(voe.requestContent.startDate).format('MM/DD/YYYY')
                : '-',
          },
          {
            accessor: 'endDate',
            title: (
              <Tooltip label="Employment End date">
                <p>End Date</p>
              </Tooltip>
            ),
            cellsClassName: 'w-[10%]',
            render: (voe) =>
              voe.requestContent.endDate
                ? dayjs(voe.requestContent.endDate).format('MM/DD/YYYY')
                : '-',
          },
          {
            accessor: 'jobDuration',
            title: (
              <Tooltip label="Job Duration">
                <p>Job Duration</p>
              </Tooltip>
            ),
            cellsClassName: 'w-[10%]',
            render: (voe) => calculateAverageJobDuration([voe.requestContent]),
          },
          {
            accessor: 'actions',
            title: 'Actions',
            cellsClassName: 'w-[4%]',
            render: (voe) => (
              <div className="flex w-full flex-row items-center justify-center  ">
                <Tooltip label="See details">
                  <ActionIcon
                    variant="subtle"
                    className="h-6 w-6"
                    onClick={() => onAction(voe)}
                  >
                    <IconNavigate className=" fill-doubleNickel-white stroke-doubleNickel-brand-500" />
                  </ActionIcon>
                </Tooltip>
              </div>
            ),
          },
        ]}
        records={voeList}
      />
    </div>
  )
}

export default VoERequestsTable
